import './payment-collection.scss';
import { useAppSelector } from '@/state/hooks';
import { useGetInvoiceDetailsQuery } from '@/state/slices/invoices/invoicesApiSlice';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LeftArrow } from '../../../assets/svgs/left-arrow.svg';
import { ReactComponent as Camera } from '@/assets/svgs/camera-non-checked.svg';
import { ReactComponent as BlueCamera } from '@/assets/svgs/camera-checked.svg';
import { ReactComponent as BlueCameraUnchecked } from '@/assets/svgs/camera-blue-unchecked.svg';
import { ReactComponent as Pen } from '@/assets/svgs/pen.svg';
import { ReactComponent as PenGray } from '@/assets/svgs/pen-gray.svg';
import { ReactComponent as UnitImage1 } from '@/assets/svgs/unit-img-1.svg';
import { ReactComponent as CloseXModel } from '@/assets/svgs/closemodal.svg';
import { ReactComponent as Download } from '@/assets/svgs/Download-svg.svg';
import { ReactComponent as CameraUpload } from '@/assets/svgs/camera.svg';
import { ReactComponent as Loading } from '@/assets/svgs/loading.svg';
import { ReactComponent as Close } from '@/assets/svgs/Close.svg';
import { Button, Container, Modal, Offcanvas } from 'react-bootstrap';
import useGoBackOrRedirect from '@/utils/hooks/goBackOrRedirect';
import InvoiceTableTh from '../invoices/(invoicesList)/components/InvoiceTableTh';
import { numberWithCommas, userToken } from '@/utils/helper';
import axios from 'axios';
import { HOST, TOKEN_STORAGE } from '@/utils/constants';
import { CloseButton } from 'react-bootstrap';
import UploadImage from "../upload_image";

interface PaymentValues {
  cash?: number; // '?' allows this property to be optional
  online?: number; // Add other properties if needed
  cheque?: number; // Add other properties if needed
  credit?: number; // Add other properties if needed
  credit_image?: File; // Credit image (if required)
  cheque_image?: File;
}
interface Product {
  id: number;
  checked: boolean;
  damaged_item: string;
}
interface ImageFiles {
  credit_image: string;
  cheque_image: string;
}

interface ItemData {
  id: string;
  quantity: string;
  checked: boolean;
  image?: File;
}

interface productValueType {
  [key: string]: ItemData;
}

interface ProductHead {
  head: string;
  sortable: boolean;
  key: string;
}

type ProductValueObj = Record<string, any>;
interface ProductReturn {
  setPrice: (value: string) => void;
  setReturnItem: (value: number) => void;
  setProductValue: React.Dispatch<React.SetStateAction<ProductValueObj>>;
  productValue: ProductValueObj;
  isShowModal?: boolean;
  setIsShowModal?: (value: boolean) => void;
  customInvoiceId?: number;
  productHead?: ProductHead[];
  modalSize?: string;
}
const HandleModelReturn: React.FC<ProductReturn> = ({
  setPrice,
  setReturnItem,
  setProductValue,
  productValue,
  isShowModal,
  setIsShowModal,
  customInvoiceId,
  productHead,
  modalSize,
}) => {
  const all_data = [
    {
      id: 1,
      damaged_item: 'test1',
      unit: {
        unit: 0,
        showUnit: false,
      },
      checked: false,
    },
    {
      id: 2,
      damaged_item: 'test2',
      unit: {
        unit: 5,
        showUnit: true,
      },
      checked: true,
    },
    {
      id: 3,
      damaged_item: 'test3',
      unit: {
        unit: 0,
        showUnit: false,
      },
      checked: false,
    },
  ];
  const product_data = [
    {
      head: 'Damaged Item',
      sortable: false,
      key: 'damaged_item',
    },
    {
      head: 'Unit',
      sortable: false,
      key: 'unit',
    },
    {
      head: 'Price',
      sortable: false,
      key: 'price',
    },
    {
      head: 'Image',
      sortable: false,
      key: 'image',
    },
  ];
  const invoiceState = useAppSelector((state) => state.invoices);
  const { invoice_id } = useParams();
  const {
    data: invoiceData,
    isLoading,
    refetch,
  } = useGetInvoiceDetailsQuery(
    customInvoiceId ? customInvoiceId : invoice_id,
    {
      // skip: isNaN(invoiceNo), // Skip the query if batchId is NaN
    }
  );
  const [values, setValues] = useState<PaymentValues>({});
  const [total, setTotal] = useState(0);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: Number(value), // Convert string to number
    });
  };
  const navigate = useNavigate();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [show, setShow] = useState(isShowModal || false);
  const [editMode, setEditMode] = useState<{ [key: number]: boolean }>({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [productData, setProductData] = useState<ProductHead[]>(
    productHead || product_data
  );
  const { goBackOrRedirect } = useGoBackOrRedirect();
  const [sortingStates, setSortingStates] = useState({
    sortBy: '',
    isAsc: true,
  });

  const [loadingReturn, setLoadingReturn] = useState<boolean>(false);

  const formatIndianCurrency = (value: number): string => {
    return value.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR', // Indian Rupee
    });
  };

  useEffect(() => {
    if (productHead) {
      setProductData(productHead);
    }
  }, [productHead]);

  useEffect(() => {
    if (setIsShowModal) {
      setIsShowModal(show);
    }
  }, [show]);

  useEffect(() => {
    if (
      Object.keys(productValue).length <= 0 ||
      Object.keys(productValue).length == 0
    ) {
      const updatedProductdata = invoiceData?.items?.map((invoice) => ({
        productId: invoice.id,
        id: invoice?.id,
        quantity: Number(invoice?.quantity)?.toFixed(0),
        unit_price: invoice?.unit_price,
      }));
      console.log(updatedProductdata, 'updatedProductdata============');
      const data = updatedProductdata?.flatMap((item: any) => {
        return (invoiceData?.returns || []).filter((x: any) => {
          return x.reference_id === item.id;
        });
      });
      // console.log(data, "data===================", invoiceData?.items)
      data?.map((value, i) => {
        setProductValue((prevproductValue) => ({
          ...prevproductValue,
          [value.reference_id]: {
            ...(prevproductValue[value.id] || {}),
            checked: true,
            id: value.reference_id,
            quantity: Number(value.quantity).toFixed(0),
            unit_price: parseFloat(value?.unit_price) || 0,
            total:
              Number(value?.unit_price) *
              (Boolean(value.quantity) ? Number(value.quantity) : 0),
            image: value?.image_path,
            // image_show: value?.image_path && (window.location.host.includes('.vercel.app') || process.env.NODE_ENV == "development" ? 'https://abc.indelivtest.in' : "https://" + window.location.host) + "/tenants/abc/returns/" + value?.image
            image_file: value?.image,
          },
        }));
      });
      let totalAmount = 0;
      data?.map((p: any, index: any) => {
        let total =
          Number(p?.unit_price) *
          (Boolean(p.quantity) ? Number(p.quantity) : 0);
        totalAmount += total;
      });
      let qty = Number(invoiceData?.returns_quantity);
      if (totalAmount == 0) {
        setPrice('₹00.00');
      } else {
        const formattedAmount = formatIndianCurrency(totalAmount);
        setPrice(formattedAmount);
        setReturnItem(qty);
      }
    }
  }, [invoiceData?.returns]);
  // console.log(productValue, "productValue-----------------")
  const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const updatedProductdata = invoiceData?.items?.map((invoice: any) => ({
      productId: invoice.id,
      id: invoice.id,
      checked: checked,
      quantity: Number(invoice?.quantity)?.toFixed(0),
      unit_price: invoice?.unit_price,
    }));
    // if (checked) {
    updatedProductdata?.map((data) => {
      setProductValue((prevproductValue) => ({
        ...prevproductValue,
        [data.productId]: {
          ...(prevproductValue[data.productId] || {}),
          checked: data.checked,
          id: data.id,
          unit_price: parseFloat(data?.unit_price) || 0,
        },
      }));
    });
    // }
    // else {
    // updatedProductdata?.map(data => {
    //   delete productValue?.[data.productId]
    // })
    // setProductValue({ ...productValue })
    // }
    // setProductData([...updatedProductdata]);
  };
  
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    Id: number,
    productId: number,
    qty: number,
    unit_price: number,
    item_name: string
  ) => {
    let checked = e.target?.checked;
    // if (e.target?.checked) {
    setProductValue((prevproductValue) => ({
      ...prevproductValue,
      [productId]: {
        // Ensure other properties are maintained if they exist
        ...(prevproductValue[productId] || {}),
        checked: checked,
        id: Id, // 'unit' should be a defined value
        unit_price: Number(unit_price) || 0,
        item_name: item_name,
      },
    }));
    // }
    // else {
    //   delete productValue?.[Id];
    //   setProductValue({ ...productValue })
    // setProductValue((prevproductValue) => ({
    //   ...prevproductValue,
    //   [productId]: {
    //     // Ensure other properties are maintained if they exist
    //     ...(prevproductValue[productId] || {}),
    //     checked: false,
    //     productId: "",
    //     quantity: ""// 'unit' should be a defined value
    //     // 'unit' should be a defined value
    //   },
    // }));
    // }
  };

  useEffect(() => {
    // let data = productData.filter((_el) => _el.checked);
    let qty = 0;
    for (const key in productValue) {
      const item = productValue[key];
      if (item.checked && item?.quantity > 0) {
        qty += Number(item?.quantity);
      }
    }
    setTotal(qty);
  }, [productValue]);

  const handlePenClick = (id: any) => {
    setEditMode((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleBlurReturn = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    setEditMode((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [totalDue, setTotalDue] = useState(Number(invoiceData?.invoice_amount));
  useEffect(() => {
    const invoiceAmount = Number(invoiceData?.invoice_amount) ?? 0;

    // Calculate the sum of all payments, defaulting to 0 if undefined
    const cash = Number(values?.cash) || 0;
    const credit = Number(values?.credit) || 0;
    const cheque = Number(values?.cheque) || 0;
    const online = Number(values?.online) || 0;

    // Calculate the total paid
    const totalPaid = cash + credit + cheque + online;

    // Calculate the remaining balance
    const remainingBalance = invoiceAmount - totalPaid;
    setTotalDue(remainingBalance);
  }, [values]);

  const [isEditing, setIsEditing] = useState(false);
  const [unit, setUnit] = useState<number>();
  const [update, setupdated] = useState([]);

  const handleClick = () => {
    setIsEditing(true);
    // setUnit(productData); // Set initial value of input to the current unit value
  };

  const handleInputChanges = (
    productId: number,
    value: number,
    quantity: any
  ) => {
    // if (!Number.isNaN(value)) {
    setUnit(Boolean(value) ? Number(value) : 0);
    // }
    let qty = parseInt(quantity);

    if (value > qty) {
      alert(
        'Entered quantity is more than the original quantity in the invoice.'
      );
    } else {
      setProductValue((prevproductValue) => ({
        ...prevproductValue,
        [productId]: {
          // Ensure other properties are maintained if they exist
          ...(prevproductValue[productId] || {}),
          quantity: Boolean(value) ? Number(value) : '',
          total:
            Number(productValue?.[productId]?.unit_price) *
            (Boolean(value) ? Number(value) : 0),
          // id: productId // 'unit' should be a defined value
        },
      }));
    }
  };

  // -------------image_upload----------
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string>('');
  const [triggerUpload, setTriggerUpload] = useState<boolean>(false);

  const openImage = (imageName: number) => {
    setImageName(imageName.toString())
    setIsImageUpload(true)
  }

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
    index: number
  ) => {
    let file = e?.currentTarget?.files?.[0];

    // checking image file
    if (file) {
      const base64 = await toBase64(file);
      const selectedProduct = productValue[Number(imageName)];
      const selectedProductImage = selectedProduct?.image;
      const selectedProductImageFile = selectedProduct?.image_file;

      // add/replace image to list
      setProductValue((prevData: any) => ({
        ...prevData,
        [Number(imageName)]: {
          ...selectedProduct,
          image_file: selectedProductImageFile?.length ? index > selectedProductImageFile?.length - 1 ? 
            [...selectedProductImageFile, file] : 
            selectedProductImageFile.map((item: any, idx: number) => idx === index ? file : item) : [file],
          image: selectedProductImage?.length ? index > selectedProductImage?.length - 1 ? 
            [...selectedProductImage, base64] : 
            selectedProductImage.map((item: any, idx: number) => idx === index ? base64 : item) : [base64],
        }
      }))
    }
  };

  const handleDeleteImage = (data: any) => {
    const selectedProduct = productValue[Number(imageName)];

    // we should to delete image from "image_file" and "image", but we only have filtered list from "image"
    // so we need to get index of image in "image" to delete image from "image_file"
    const image = selectedProduct?.image;
    const filteredImage = data?.image;

    const missingIndex = image
      .map((base64: string, idx: number) => filteredImage?.includes(base64) ? -1 : idx)
      .filter((index: number) => index !== -1);

    // filter "image_file" with missingIndex
    setProductValue((prevData: any) => ({
      ...prevData, 
      [Number(imageName)]: {
        ...selectedProduct,
        image: [...filteredImage],
        image_file: [...selectedProduct.image_file.filter((item: any, idx: number) => missingIndex.includes(idx))]
      }
    }));
  }

  useEffect(()=> {console.log('productValue', productValue)}, [productValue])

  const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert file to Base64'));
        }
      };
      reader.onerror = () => {
        reject(new Error('Error occurred while reading the file'));
      };
    });
  };

  useEffect(() => {
    if (triggerUpload) {
      const uploadInput = document.getElementById('upload-image');
      uploadInput?.click(); // Programmatically click the hidden input field
      setTriggerUpload(false); // Reset the trigger
    }
  }, [triggerUpload]);

  const [qtyError, setQtyError] = useState<any>([]);

  const handleReturnSubmit = async () => {
    var totalAmount = 0;
    var totalQuantity = 0;
    let qtyId = [];
    let allCheckedItemsHaveQuantity = true;
    for (const key in productValue) {
      const item = productValue[key];
      if (item.checked) {
        if (item?.quantity > 0) {
          totalQuantity += item?.quantity;
          if (item?.total) {
            totalAmount += item?.total;
          }
        } else {
          // console.log(item?.id, "item?.iditem?.iditem?.id")
          qtyId.push(item?.id);
          allCheckedItemsHaveQuantity = false;
        }
      }
    }
    setQtyError([...qtyId]);
    const formattedAmount = formatIndianCurrency(totalAmount);
    setPrice(formattedAmount);
    setReturnItem(totalQuantity);
    // handleClose();
    if (allCheckedItemsHaveQuantity) {
      handleClose();
    }
  };
  return (
    <>
      {!show && (
        <span
          className="col-2 text-decoration-underline align-self-center AddLink"
          onClick={handleShow}
        >
          {Object?.keys(productValue).length > 0 ? 'Edit' : 'Add'}
        </span>
      )}
      {/* className={`modal-bottom modal_payment`} */}
      <Modal
        fullscreen
        show={show}
        onHide={handleClose}
        className={`custom-returnproduct-modal image-upload-no-padding ${modalSize ? modalSize : 'custom-modal-lg'}`}
      >
        {isImageUpload ? (
          <>
          <UploadImage
            setisImageUpload={setIsImageUpload}
            handleChange={handleChange}
            fileImage={{...productValue[imageName], image: productValue[imageName]?.image ?? [] }}
            setFileImage={(val) => handleDeleteImage(val)}
            imageName={'image'}
          />
          </>
        ) : (
          <Modal.Body style={{ padding: 0 }}>
            <div
              className="d-flex justify-content-center align-items-center custom-body-container"
              style={{ height: '100%' }}
            >
              <div className="custom-body">
                <ul
                  className="list-unstyled overflow-hidden"
                  style={{ height: '100%' }}
                >
                  <li
                    className="payment-head d-flex flex-row justify-content-between"
                    style={{
                      backgroundColor: '#F9FAFB',
                      padding: '12px 20px',
                      borderBottom: '1px solid #E6E8EC',
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <LeftArrow
                        onClick={handleClose}
                        className="arrow_icon align-self-center desktop-only-product"
                        style={{ marginRight: 10 }}
                      />
                      <span className="title"> Product Returns</span>
                      <span
                        className="selectedItemProduct d-flex justify-content-center"
                        style={{ fontSize: '16px', fontWeight: '500' }}
                      >
                        {total}
                      </span>
                    </div>
                    <div className="mobile-only-product">
                      <button
                        style={{
                          backgroundColor: 'transparent',
                          margin: '0px',
                        }}
                        className="Close_btn"
                        onClick={handleClose}
                      >
                        <CloseXModel />
                      </button>
                    </div>
                  </li>
                  <li className="custom-content-container">
                    <div className="custom-content">
                      {/* mobile view */}
                      <div className="mobile-only-product">
                        {invoiceData?.items?.map((product, index) => (
                          <div
                            className="w-100 product_return mb-3"
                            key={index}
                          >
                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex product_mob_productValue"
                                style={{ padding: '12px' }}
                              >
                                <label htmlFor={`check-${product?.id}`}>
                                  <input
                                    type="checkbox"
                                    checked={
                                      productValue?.[product?.id]?.checked
                                        ? productValue?.[product?.id]?.checked
                                        : false
                                    }
                                    id={`check-${product?.id}`}
                                    className={`ivdt-checked ${
                                      productValue?.[product?.id]?.checked &&
                                      productValue?.[product?.id]?.checked &&
                                      'active'
                                    }`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e,
                                        product?.id,
                                        product?.id,
                                        product?.quantity,
                                        product?.unit_price,
                                        product?.item_name
                                      )
                                    }
                                  />
                                </label>
                                <div className="product_name text-dark">
                                  {product?.item_name}
                                </div>
                              </div>
                              <div className="p-2">
                                ₹{numberWithCommas(product?.unit_price)}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center  product_mob_sec">
                              <div
                                className={`text-decoration-underline units_name d-flex`}
                                style={{
                                  whiteSpace: 'nowrap',
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: productValue?.[product?.id]?.checked
                                    ? qtyError?.includes(product?.id)
                                      ? 'red'
                                      : '#0080FC'
                                    : 'text-gray-return',
                                }}
                              >
                                <>
                                  {' '}
                                  {editMode[product?.id] ? (
                                    <label className="cust-lable">
                                      <span id="tpl" className="tpl">{` ${
                                        productValue?.[product?.id]?.quantity ||
                                        ''
                                      } `}</span>
                                      <input
                                        type="number"
                                        style={{
                                          border: 'none',
                                          outline: 'none',
                                          color: qtyError?.includes(product?.id)
                                            ? 'red'
                                            : '#0080FC',
                                          background: '#F9FAFB',
                                        }}
                                        className="test form-control form-control-sm mobile-input-product-return"
                                        id="colFormLabelSm"
                                        // onChange={(e) =>
                                        //   handleUnitChange(product.id, parseInt(e.target.value))
                                        // }
                                        value={
                                          productValue?.[product?.id]?.quantity
                                        }
                                        onChange={(e) =>
                                          handleInputChanges(
                                            product?.id,
                                            parseInt(e.target.value),
                                            product?.quantity
                                          )
                                        }
                                        onBlur={(e) =>
                                          handleBlurReturn(e, product?.id)
                                        }
                                        // onKeyDown={(event) =>
                                        //   handlekyedown(event, product?.id)
                                        // }
                                        autoFocus
                                      />
                                    </label>
                                  ) : productValue?.[product?.id]?.quantity ? (
                                    Number(
                                      productValue?.[product?.id]?.quantity
                                    ).toFixed(1)
                                  ) : (
                                    '0.0'
                                  )}{' '}
                                  unit
                                </>
                                <span className="m-2">
                                  {productValue?.[product?.id]?.checked ? (
                                    <Pen
                                      onClick={() =>
                                        handlePenClick(product?.id)
                                      }
                                    />
                                  ) : (
                                    <PenGray />
                                  )}
                                </span>
                              </div>
                              {productData.some(
                                (item) => item.key === 'image'
                              ) && (
                                <div>
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      height: '31px',
                                      width: '31px',
                                    }}
                                  >
                                    {productValue?.[product?.id]?.checked ? (
                                      productValue?.[product?.id]
                                        ?.image ? (
                                        <BlueCamera
                                          style={{
                                            height: '31px',
                                            width: '31px',
                                          }}
                                          onClick={() => openImage(product?.id)}
                                        />
                                      ) : (
                                        <BlueCameraUnchecked
                                          style={{
                                            height: '31px',
                                            width: '31px',
                                          }}
                                          onClick={() => openImage(product?.id)}
                                        />
                                      )
                                    ) : (
                                      <Camera
                                        style={{
                                          height: '31px',
                                          width: '31px',
                                        }}
                                      />
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* desktop view */}
                      <div className="desktop-only-product">
                        {/* product-return-table */}
                        <table
                          className="product-return-table"
                          //   style={{ borderRadius: "12px" }}
                        >
                          <thead>
                            <tr>
                              {true && (
                                <th className="checkbox-th">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <input
                                      placeholder="test"
                                      type="checkbox"
                                      className="checkbox"
                                      checked={selectAllChecked}
                                      onChange={handleSelectAllCheckbox}
                                    />
                                  </div>
                                </th>
                              )}

                              {productData.map((heading) => (
                                <InvoiceTableTh
                                  key={heading.head}
                                  heading={heading}
                                  setSortingStates={setSortingStates}
                                  moveToTop={() => {}}
                                  // withSorting={location?.pathname?.includes("batchs")?true:showCheckbox}
                                />
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {invoiceData?.items?.map((product, index) => (
                              <tr
                                key={index}
                                className={`${
                                  productValue?.[product?.id]?.checked
                                }`}
                              >
                                <td className="check align-self-center">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {/* <label htmlFor={`check-${product?.id}`}> */}
                                    <input
                                      type="checkbox"
                                      checked={
                                        productValue?.[product?.id]?.checked
                                          ? productValue?.[product?.id]?.checked
                                          : false
                                      }
                                      id={`check-${product?.id}`}
                                      className={`ivdt-checked ${
                                        productValue?.[product?.id]?.checked &&
                                        productValue?.[product?.id]?.checked &&
                                        'active'
                                      }`}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          product?.id,
                                          product?.id,
                                          product?.quantity,
                                          product?.unit_price,
                                          product?.item_name
                                        )
                                      }
                                    />
                                    {/* </label> */}
                                  </div>
                                </td>
                                <td className="item company other">
                                  {product?.item_name}
                                </td>
                                <td className="company other unit">
                                  {productValue?.[product?.id]?.checked ? (
                                    <Container
                                      className="d-flex align-items-center justify-content-center"
                                      style={{
                                        width: '60px',
                                        height: '36px',
                                        border: qtyError?.includes(product?.id)
                                          ? '1px dashed  red'
                                          : '1px dashed  #0080FC',
                                        borderRadius: '8px',
                                        padding: '10px 16px',
                                        margin: '0px',
                                        display: 'inline-block',
                                      }}
                                      onClick={() =>
                                        handlePenClick(product?.id)
                                      }
                                      //   onClick={handleClick}
                                    >
                                      {/* {editMode[product?.id] ? ( */}
                                      <input
                                        style={{
                                          width: '38px',
                                          height: '30px',
                                          border: 'none',
                                          outline: 'none',
                                          background: 'transparent',
                                          color: '#0080FC',
                                          textAlign: 'center',
                                        }}
                                        type="text"
                                        // value={unit}
                                        value={
                                          productValue?.[product?.id]?.quantity
                                        }
                                        onChange={(e) =>
                                          handleInputChanges(
                                            product?.id,
                                            parseInt(e.target.value),
                                            product?.quantity
                                          )
                                        }
                                        //   onChange={handleInputChanges}
                                        // onKeyDown={(event) =>
                                        //   handlekyedown(event, product?.id)
                                        // }
                                        autoFocus
                                      />
                                      {/* ) : (
                                <span
                                  style={{
                                    color: "#0080FC",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                >
                                  {productValue?.[product?.id]?.quantity && productValue?.[product?.id]?.quantity}
                                </span>
                                )} */}
                                    </Container>
                                  ) : (
                                    <UnitImage1 style={{ cursor: 'pointer' }} />
                                  )}
                                </td>
                                <td className="price" style={{ width: '110px' }}>
                                  ₹{numberWithCommas(product?.unit_price)}
                                </td>
                                {productData.some(
                                  (item) => item.key === 'image'
                                ) && (
                                  <td className="company other image">
                                    <span
                                      style={{
                                        cursor: 'pointer',
                                        height: '31px',
                                        width: '31px',
                                      }}
                                    >
                                      {productValue?.[product?.id]?.checked ? (
                                        productValue?.[product?.id]
                                          ?.image ? (
                                          <BlueCamera
                                            style={{
                                              height: '31px',
                                              width: '31px',
                                            }}
                                            onClick={() => openImage(product?.id)}
                                          />
                                        ) : (
                                          <BlueCameraUnchecked
                                            style={{
                                              height: '31px',
                                              width: '31px',
                                            }}
                                            onClick={() => openImage(product?.id)}
                                          />
                                        )
                                      ) : (
                                        <Camera
                                          style={{
                                            height: '31px',
                                            width: '31px',
                                          }}
                                        />
                                      )}
                                    </span>
                                  </td>
                                )}
                              </tr>
                              //     <tr>
                              //   <td colSpan={product_data.length + 1}>rf</td>
                              //   </tr>
                            ))}
                            {/* <tr className="last-row-tr">
                              <td
                                colSpan={productData.length + 1}
                                className="last-row"
                              >
                                <button
                                  className="btn-payment-return-footer desktop-only-return float-end"
                                  onClick={handleReturnSubmit}
                                  disabled={loadingReturn}
                                >
                                  {loadingReturn ? (
                                    <Loading
                                      className="loadingCircle me-2"
                                      style={{ height: '20px', width: '20px' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                  Save
                                </button>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                        <div
                          className="d-flex flex-row justify-content-end gap-3"
                          style={{
                            background: '#F9FAFB',
                            padding: '12px 24px',
                            borderRadius: '0px 0px 12px 12px',
                            outline: '1px solid #eaecf0',
                          }}
                        >
                          <button
                            className={`d-flex flex-row justify-content-center btn-payment-cancel`}
                            style={{ width: 120, fontWeight: '400' }}
                            onClick={() => {
                              if (!loadingReturn) {
                                handleClose();
                              }
                            }}
                            disabled={loadingReturn}
                          >
                            <div>Cancel</div>
                          </button>
                          <button
                            className="btn-payment-return-footer"
                            onClick={handleReturnSubmit}
                            disabled={loadingReturn}
                            style={{ width: 120, fontWeight: '400' }}
                          >
                            {loadingReturn && (
                              <Loading
                                className="loadingCircle me-2"
                                style={{ height: '20px', width: '20px' }}
                              />
                            )}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="px-md-4 custom-footer-container mobile-only-product">
                    <button
                      className="btn btn-payment-return w-100"
                      onClick={handleReturnSubmit}
                      disabled={loadingReturn}
                    >
                      {loadingReturn && (
                        <Loading
                          className="loadingCircle me-2"
                          style={{ height: '20px', width: '20px' }}
                        />
                      )}
                      Save
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};
export default HandleModelReturn;
