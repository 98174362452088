import React from 'react'
import { ReactComponent as LogoNewSvg } from "@/assets/svgs/new-indeliv-logo.svg";

const Index = () => {
  return (
    <div style={{padding:"25px"}}  >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="large-logo">
        {/* <img
          src={"/assets/image/new-indeliv-logo.png"}
          alt="Logo"
          height="50"
          width="177"
        /> */}
        <LogoNewSvg style={{ height: 50}}/>
        </div>
      </div>
      <h5 style={{marginTop:"25px"}}>
        No Account Found with requested domain
      </h5>
    </div>
  )
}

export default Index