import { useLogoutMutation } from "@/state/slices/authApiSlice";
import { useGetPaymentsMutation, useEditPaymentMutation, useDeletePaymentMutation } from "@/state/slices/payments/paymentsApiSlice";
import Payment from "@/types/Payment";
import PaymentTable from "@/pages/dashboard/payments/(paymentsList)/components/paymentTable/PaymentTable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePaymentsListState } from "../layout";
import useDebounce from "@/utils/hooks/debounce";
import { clearState, setPayments, updatePage } from "@/state/slices/payments/paymentsSlice";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { set } from "react-hook-form";
import moment from "moment";

export default function Deliveries() {
  const { searchTxt,
    from,
    to,
    showAddPaymentModal, 
    setShowAddPaymentModal,
    setDateStart,
    setFrom,
    setTo,
    setSearchTxt,
    deliveriesControllerRef: controllerRef,
  } = usePaymentsListState();
  const debouncedSearchTerm = useDebounce(searchTxt, 400);
  const paymentsState = useAppSelector((state) => state.payments);
  const payments = paymentsState.payments;
  //const [payments, setPayments] = useState<Payment[]>(initialData);
  const page = paymentsState.page;

  const [getPayments, { data, isError, isLoading, error }] =
    useGetPaymentsMutation();
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firstLoad, setFirstLoad] = useState(true); 
  const location = useLocation(); 
  const [stateDataSorting,setStateDataSorting] = useState<{sortBy: string, isAsc: boolean | null}>({sortBy: "invoice_id",isAsc: null})
  const [paymentData, setPaymentData] = useState<any>([]);

  // clear search when refresh
  useEffect(() => {
    return () => {
      setSearchTxt('');
    }
  }, [])

  // handle get payments data when first load page
  useEffect(() => {
    if (firstLoad && controllerRef?.current && !isLoading) {
      autoPopulateFilter();
    }
  }, [location.search, controllerRef?.current]);

  // handle clear data when change screen
  useEffect(() => {
    dispatch(clearState());

    return () => {
      dispatch(clearState());
      setPaymentData([]);
    };
  }, [location.pathname]);

  // handle filter
  useEffect(() => {
    console.log('debouncedSearchTerm')
    if (!firstLoad) {
      const payload = {
        page: 1,
        keyword: searchTxt,
        from: from ? moment(from).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: to ? moment(to).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
      }
      dispatch(clearState());
      setPaymentData([]);
      updateURL({
        ...payload,
        from: from,
        to: to,
      }, true);
    }
  }, [from, to, debouncedSearchTerm]);

  // handle load data when scrolling
  useEffect(() => {
    if (!paymentsState.lastPageReached && loadMoreTrigger && !isLoading && !firstLoad) {
      const payload = {
        page: Number(getQueryParam('page', page)) + 1,
        keyword: searchTxt,
        from: from ? moment(from).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: to ? moment(to).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc == null ? '' : stateDataSorting.isAsc ? "ASC" : 'DESC',
      }
      getPayments({...payload, signal: controllerRef?.current?.signal}).then((result) => {
        if('data' in result) {
          updateURL({
            ...payload,
            from: from,
            to: to,
          }, false);

          setLoadMoreTrigger(false);
        }
      });
    }
  }, [loadMoreTrigger]);

  // handle get payments
  const getPaymentsData = () => {
    let fromDate = getQueryParam('from', '') as string;
    let toDate  = getQueryParam('to', '') as string;
    let startDate, endDate, startDateUtc, endDateUtc;

    if (fromDate !== '' && toDate !== '') {
      startDate = new Date(decodeURIComponent(fromDate));
      endDate = new Date(decodeURIComponent(toDate));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      startDateUtc = moment(startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      endDateUtc = moment(endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      startDateUtc = '';
      endDateUtc = '';
    }
    
    const pageParams = Number(getQueryParam('page', 1));

    const payload = {
      page: 1,
      keyword: getQueryParam('keyword', '') as string,
      from: startDateUtc,
      to: endDateUtc,
      sort_by: getQueryParam('sort_by', 'invoice_id') as string,
      sort_order: getQueryParam('sort_order', '') as string,
      per_page: 20 * pageParams,
      signal: controllerRef?.current?.signal,
    };

    dispatch(clearState());
    setPaymentData([]);
    
    getPayments(payload).then((result) => {
      if ('data' in result) {
        dispatch(updatePage({page: pageParams}));
      }
    }).finally(() => {
      setFirstLoad(false);
    });
  }

  // handle sort data
  const getPaymentsSortBy = (stateData: { sortBy: string; isAsc: boolean | null }) => {
    if (!firstLoad) {
      dispatch(clearState());
      setPaymentData([]);
      setStateDataSorting(stateData);
      const payload = {
        page: 1,
        keyword: debouncedSearchTerm,
        from: from ? moment(from).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: to ? moment(to).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateData.sortBy,
        sort_order: stateData.isAsc ? 'ASC' : 'DESC',
      };
      updateURL({
        ...payload,
        from: from,
        to: to,
      }, true);
    }
  };

  // when data is loaded, then pass to variable
  useEffect(() => {
    if(payments.length) {
      setPaymentData([...payments]);
    }
  }, [payments]);

  // get params
  const getQueryParam = (param: string, defaultValue: string | number): string | number => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.has(param) ? urlParams.get(param)! : defaultValue;
  }

  // autoPopulate filter
  const autoPopulateFilter = () => {
    let queryParams;

    // check if have filter data on session
    if (getFilterData() !== null) {
      const queryString = getFilterData();
      const params = new URLSearchParams(queryString);
    
      const paramsObject: { [key: string]: any } = {};
      params.forEach((value, key) => {
      if (key === 'page') {
        paramsObject[key] = Number(value);
      } else {
        paramsObject[key] = value;
      }
      });
      queryParams = paramsObject;

      // remove filter data from session
      sessionStorage.removeItem('paymentFilterData');

      // update url then refresh page with filter
      setTimeout(() => {
        return updateURL(paramsObject, true);
      }, 100)
    }

    queryParams = {
      page: Number(getQueryParam('page', 1)),
      keyword: getQueryParam('keyword', '') as string,
      from: getQueryParam('from', '') as string,
      to: getQueryParam('to', '') as string,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
    };

    if (queryParams.keyword !== '') {
      setSearchTxt(queryParams.keyword)
    }
    if (queryParams.sort_by !== '') {
      setStateDataSorting({
        sortBy: queryParams.sort_by, 
        isAsc: queryParams.sort_order === '' ? null : queryParams.sort_order === 'ASC' ? true : false
      })
    }
    if (queryParams.to !== '' && queryParams.from !== '') {
      let startDate = new Date(decodeURIComponent(queryParams.from));
      let endDate  = new Date(decodeURIComponent(queryParams.to));

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      setDateStart([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      }])

      setFrom(queryParams.from);
      setTo(queryParams.to);
    }

    setTimeout(() => {
      getPaymentsData();
    }, 200)
  }

  // update url
  const updateURL = (queryParams: any, isReload: boolean) => {
    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    setFirstLoad(isReload);
    navigate(`/dashboard/payments/deliveries?${newUrl.toString()}`, { replace: true });
  }

  // save filter data to sessionStorage
  const saveFilterData = (key:string) => {
    const queryParams = {
      page: Number(getQueryParam('page', page)),
      keyword: debouncedSearchTerm,
      from: from ? from : '',
      to: to ? to : '',
      sort_by: stateDataSorting.sortBy,
      sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
    };

    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });
    
    // clear current list
    dispatch(clearState());
    setPaymentData([]);
    controllerRef?.current?.abort();
    
    sessionStorage.setItem(`paymentFilterData`, JSON.stringify(newUrl.toString()));
  }

  // get filter data from sessionStorage
  const getFilterData = () => {
    const filterData = sessionStorage.getItem('paymentFilterData');
    return filterData ? JSON.parse(filterData) : null;
  }

  useEffect(() => {
    if (isError && error && "data" in error && error?.status === 403) {
      logout();
      navigate("/");
    }
  }, [isError]);

  // replace image url
  const modifyData = (id: number, imageURL: string, imageIdx: number) => {
    const newData = paymentData.map((item: any) => {
      if (item.id === id) {
        return {
          ...item, 
          payment: {
            ...item.payment, 
            image_path: item.payment.image_path.map((img: string, imgIdx: number) => imageIdx === imgIdx ? imageURL : img)
          }
        }
      }
      return item 
    })
    setPaymentData([...newData]);
  }

  return (
    <>
      <div className="">
        <PaymentTable
          payments={paymentData ?? []}
          setPayments={(i) => dispatch(setPayments(i))}
          showCheckbox={true}
          loading={isLoading}
          loadMorePayments={setLoadMoreTrigger}
          getPaymentsSortBy={getPaymentsSortBy}
          showAddPaymentModal={showAddPaymentModal}
          setShowAddPaymentModal={setShowAddPaymentModal}
          handleDetail={saveFilterData}
          handleModifyData={modifyData}
        />
      </div>
    </>
  );
}
