import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as DownloadButton } from '@/assets/svgs/Download-svg.svg';
import { ReactComponent as PrevButton } from '@/assets/svgs/arrow-left-new.svg';
import { ReactComponent as NextButton } from '@/assets/svgs/arrow-right-new.svg';
import { ReactComponent as LineIcon } from '@/assets/svgs/line-icon.svg';
import { ReactComponent as BackIcon } from '@/assets/svgs/arrow-left.svg';
// @ts-ignore
import { useRotateImageMutation } from '@/state/slices/globals/globalsApiSlice';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import './PreviewImage.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';

const PreviewImage = (props: any) => {
  const { show, src, id, title, handleClose, updateURL } = props;
  const [rotation, setRotation] = useState<number>(0);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [imageRotateURL, setImageRotateURL] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [imageList, setImageList] = useState<string[]>([]);
  const [imageOpt, setImageOpt] = useState<{
    width: number;
    height: number;
    ratio: string;
    pathname: string;
  }>({
    width: 0,
    height: 0,
    ratio: '3/4',
    pathname: '',
  });
  const [imageMaxSize, setImageMaxSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rotateImage, { isLoading }] = useRotateImageMutation();
  const [isLoadingFreshURL, setIsLoadingFreshURL] = useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [backgroundPosition, setBackgroundPosition] = useState<string>('0% 0%');

  const isSmallScreen = useMemo<boolean>(() => {
    return windowWidth <= 500;
  }, [windowWidth]);

  useEffect(() => {
    // re-init image when the selected image is changes
    handleImageSize();
  }, [imageOpt?.width, imageOpt?.height]);

  useEffect(() => {
    if (!show) {
      // reset data when modal is closed
      setIsZoomed(false);
      setImageRotateURL('');
      setSelectedImage(0);
      setImageList([]);
    } else {
      // set image list when modal is opened
      setImageList([...src]);
    }
  }, [show]);

  useEffect(() => {
    // setup selected image to be displayed and zoom function
    const imgSrc = imageList[selectedImage];

    const img = new Image();
    img.src = imgSrc;

    img.onload = () => {
      const imageURL = new URL(img.src);
      const imageWidth = img.naturalWidth;
      const imageHeight = img.naturalHeight;

      const gcd = (a: number, b: number): number =>
        b === 0 ? a : gcd(b, a % b);
      const commonDivisor = gcd(imageWidth, imageHeight);
      const imageRatio = `${imageWidth / commonDivisor}/${
        imageHeight / commonDivisor
      }`;
      
      setIsPortrait(imageHeight > imageWidth);
      setImageOpt({
        width: imageWidth,
        height: imageHeight,
        ratio: imageRatio,
        pathname: imageURL.pathname,
      });
    };
  }, [src, selectedImage, imageList]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageSize = () => {
    const figureElement = document.getElementById('custom-img');

    if (figureElement) {
      figureElement.style.maxWidth = `${vwToPixels(100) - 32 - 30}px`;
      figureElement.style.maxHeight = `${
        vhToPixels(100) - 32 - 62 - 30 - 20 - 95
      }px`;
      figureElement.style.aspectRatio = imageOpt?.ratio;
      figureElement.style.borderRadius = '7px';
      figureElement.style.width = `auto`;
      figureElement.style.height = `auto`;

      // special condition
      if (isSmallScreen && imageOpt.width < imageSize?.maxWidth) {
        figureElement.style.width = `${imageSize?.maxWidth}px`;
        figureElement.style.height = `auto`;
      } else if (!isSmallScreen && imageOpt.height < imageSize?.maxHeight) {
        if (isPortrait) {
          figureElement.style.width = `auto`;
          figureElement.style.height = `${imageSize?.maxHeight}px`;
        } else {
          figureElement.style.width = `auto`;
          figureElement.style.height = `auto`;
        }
      }
    }
  };

  const handleRotateImage = async (key: string) => {
    setIsZoomed(false);
    const newRotation = key === 'right' ? 270 : 90;

    const payload = {
      image_path: imageOpt.pathname,
      angle: newRotation.toString(),
    };

    // call API rotate image
    const result: any = await rotateImage(payload);

    if ('data' in result) {
      const URL = result?.data?.image_url;
      
      // replace the new image URL to the list
      setImageList((prev: any) =>
        prev.map((item: string, index: number) =>
          index === selectedImage ? URL : item
        )
      );
      updateURL(URL, selectedImage);
    } else if (result?.error) {
      toast.error(result?.error?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: 'light',
      });
    }

    setRotation(newRotation);
  };

  const vhToPixels = (vh: number): number => {
    const viewportHeight = window.innerHeight;
    return (vh * viewportHeight) / 100;
  };

  const vwToPixels = (vw: number): number => {
    const viewportWidth = window.innerWidth;
    return (vw * viewportWidth) / 100;
  };

  const imageSize = useMemo<any>(() => {
    const result = {
      maxWidth: vwToPixels(100) - 32 - 30,
      maxHeight: vhToPixels(100) - 32 - 62 - 30 - 20 - 95,
      aspectRatio: imageOpt?.ratio,
    };
    setImageMaxSize({ width: result?.maxWidth, height: result?.maxHeight });
    return result;
  }, [imageOpt?.ratio, imageOpt?.width, imageOpt.height]);

  const getImagePathname = (src: string) => {
    const imagePathname = src.match(/(\/[a-z]+\/[^/]+\.(png|jpg))/i);
    return imagePathname ? imagePathname[1] : '';
  };

  const handleDownloadImage = async () => {
    setIsLoadingFreshURL(true);
    let imageTitle = imageOpt?.pathname.replace('/def/', '');

    const imagePathname = getImagePathname(imageList[selectedImage]);

    const payload = {
      image_path: imageOpt.pathname !== '' ? imageOpt.pathname : imagePathname,
      angle: '360',
    };

    const result: any = await rotateImage(payload);

    if ('data' in result && 'image_path' in result?.data) {
      const URL = result?.data?.image_url;

      try {
        const response = await fetch(URL);

        if (!response.ok) {
          throw new Error('Failed to fetch image.');
        }

        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = imageTitle;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Download failed:', error);
      } finally {
        setIsLoadingFreshURL(false);
      }
    } else {
      setIsLoadingFreshURL(false);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    const imgElement = e.currentTarget;

    const { left, top, width, height } = imgElement.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    setBackgroundPosition(`${x}% ${y}%`);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        className="preview-image-modal asdasd"
      >
        <Modal.Body className="preview-image-container" onClick={handleClose}>
          <div
            className="preview-image-box"
            onClick={(e) => {
              e.stopPropagation();
              setIsZoomed(false);
            }}
          >
            <div className="preview-image-box-header">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <BackIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
                <p className="preview-image-box-header-title" id={'header-box'}>
                  {id ?? ''} - {title ?? ''}
                </p>
              </div>
              <div>
                <DownloadButton
                  onClick={() => {
                    if (!isLoadingFreshURL) {
                      handleDownloadImage();
                    }
                  }}
                  style={{
                    width: 40,
                    height: 36,
                    cursor: isLoadingFreshURL ? 'not-allowed' : 'pointer',
                  }}
                />
              </div>
            </div>
            <div className="preview-image-box-content">
              <div
                className={`image-container ${
                  isSmallScreen ? 'disable-zoom' : ''
                }`}
              >
                {isLoading && !isLoadingFreshURL ? (
                  <tr className="loading-row">
                    <Spinner animation="border" variant="primary" />
                  </tr>
                ) : (
                  <div
                    style={{
                      maxWidth: imageSize?.maxWidth,
                      maxHeight: imageSize?.maxHeight,
                      aspectRatio: imageSize?.aspectRatio,
                      borderRadius: 7,
                    }}
                  >
                    <figure
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsZoomed(!isZoomed);
                      }}
                      onMouseMove={isZoomed ? handleMouseMove : undefined}
                      id={'custom-img'}
                      className={`custom-img`}
                      style={{
                        backgroundImage: `url(${
                          isZoomed
                            ? imageRotateURL !== ''
                              ? imageRotateURL
                              : imageList[selectedImage]
                            : ''
                        })`,
                        backgroundSize: isZoomed ? '200%' : '100%',
                        backgroundPosition: backgroundPosition,
                        cursor: isZoomed ? 'zoom-out' : 'zoom-in',
                      }}
                    >
                      <img
                        src={imageList[selectedImage]}
                        alt="image"
                        width={'100%'}
                        height={'100%'}
                        style={{ opacity: isZoomed ? 0 : 1 }}
                      />
                      {/* <img
                        src={imageRotateURL !== '' ? imageRotateURL : src}
                        alt="image"
                        width={'100%'}
                        height={'100%'}
                        style={{ opacity: isZoomed ? 0 : 1 }}
                      /> */}
                    </figure>
                  </div>
                )}
              </div>
              <div className="image-controls-container">
                <div className="image-list-container">
                  <Swiper
                    slidesPerView={'auto'}
                    scrollbar={false}
                    freeMode={true}
                    modules={[FreeMode, Scrollbar]}
                    className="swiper-container"
                    direction={'horizontal'}
                  >
                    {imageList.map((item: any, index: number) => {
                      return (
                        <SwiperSlide
                          className={`image-item ${imageList.length - 1 === index ? '' : 'right-space'}`}
                          key={index}
                          onClick={() => setSelectedImage(index)}
                        >
                          {item !== '' ? (
                            <img
                              src={item}
                              alt="image-item"
                              className={`img ${
                                selectedImage === index ? 'active' : ''
                              }`}
                            />
                          ) : null}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <div className="image-controls">
                    <PrevButton
                      onClick={() => handleRotateImage('left')}
                      style={{ width: 24, height: 24, cursor: 'pointer' }}
                    />
                    <LineIcon />
                    <NextButton
                      onClick={() => handleRotateImage('right')}
                      style={{ width: 24, height: 24, cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewImage;
