import LoadingTd from "@/components/LoadingTd";
import "./data-table.scss";
import moment from "moment";

interface Options {
  smallPadding?: boolean;
}

export default function DataTable<T extends Record<string, any>>({
  headings,
  data,
  keys,
  options,
  loading = false,
}: {
  headings: string[];
  data: T[];
  keys?: (keyof T)[];
  options?: Options;
  loading?: boolean;
}) {
  if (!keys) keys = headings;

  // function display date by local timezone
  const formatDate = (dateString: string) => {
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();

    // check if the year is same with current year
    const currentYear = moment().year();
    const dateYear = localMoment.year();

    let formattedDate = dateYear === currentYear 
      ? localMoment.format('DD MMM')
      : localMoment.format('DD MMM, YYYY');
      
    return formattedDate;
  };

    return (
    <table className="data-table-component">
      <thead>
        <tr>
          {headings.map((h) => (
            <th>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr className="loading-row">
            <LoadingTd cols={headings.length} />
          </tr>
        ) : (
          data?.map((d) => (
            <tr>
              {keys?.map((k) => (
                <td
                  className={`${options?.smallPadding || k === 'Action' ? "small-padding" : ""} ${
                    k as string
                  }`}
                >
                 {
                   k == "created_at" ? (
                    formatDate(d[k])
                    ) : k == "user.name" ? (
                      d["user"] ? d["user"]["name"] : ''
                    ) : k == "payment_method" ? "Cash" : (
                      d[k]  
                    )
                 }
                  
                  
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}
